// ==== POLYFILLS LOADER ==== //

// Load the legacy polyfills bundle
import './polyfills/polyfills-legacy'

// Track polyfill bundle usage; note: this should be picked up even if GA hasn't been initialized since we're stashing a queue in the `head` element
if (__FEATURES__.googleAnalytics) {
	window?.gtag('event', 'polyfill', {
		action: 'load',
	})
}
